import { Button } from "@material-ui/core";
import styled from "styled-components";

import { theme } from "../constants";

const ColoredButton = styled(Button)<any>`
  background-color: ${(props) => props.bgColor};
  color: white;
  align-self: center;
  display: flex;

  padding: ${theme.spacing(0.5)}px ${theme.spacing(1)}px;
  margin: ${theme.spacing(1)}px ${theme.spacing(1)}px;

  &:hover {
    background-color: ${(props) => props.hoverBgColor};
  }
`;

export default ColoredButton;
