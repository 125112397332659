import { Typography } from "@material-ui/core";
import React from "react";

import { theme } from "../constants";
import CustomLink from "./CustomLink";

export { default as Box } from "./Box";
export { default as ButtonLink } from "./ButtonLink";
export { default as Characters } from "./Characters";
export { default as ColoredButton } from "./ColoredButton";
export { default as CustomLink } from "./CustomLink";
export { default as Games } from "./Games";
export { default as GameSeriesComponent } from "./GameSeries";
export { default as Header } from "./Header";
export { default as Route } from "./Route";
export { default as SelectText } from "./select/SelectText";
export { default as UserMenu } from "./UserMenu";
export { default as Button } from "@material-ui/core/Button";
export { default as Paper } from "@material-ui/core/Paper";
export { default as Typography } from "@material-ui/core/Typography";

export const TextLogo = ({ small = false }: { small?: boolean }) => (
  <CustomLink to="/">
    <Typography
      style={{ fontFamily: "Inter Medium" }}
      variant={small ? "h6" : "h2"}
      component="h1"
      align="center"
    >
      <span style={{ color: "white" }}>Gamer</span>
      <span style={{ color: theme.palette.secondary.main }}>Cards</span>
    </Typography>
  </CustomLink>
);
