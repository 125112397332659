import { Box } from "@material-ui/core";
import React from "reactn";
import styled from "styled-components";

import { INVITE_URL, SUPPORT_SERVER_INVITE, theme } from "../constants";
import { TextLogo } from ".";
import CustomLink from "./CustomLink";

const FooterContainer = styled.footer`
  background: ${theme.palette.primary.main};
  width: 100%;
  padding: 0px 20%;
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down("xs")} {
    padding: 0px ${theme.spacing(2)}px;
  }
`;

const Footer: React.FC = () => (
  <FooterContainer>
    <Box p={3}>
      <div style={{ color: "white!important" }}>
        <CustomLink to={INVITE_URL}>Invite The Bot</CustomLink>
        <br />
        <CustomLink to={SUPPORT_SERVER_INVITE}>Support Server</CustomLink>
      </div>
    </Box>
    <Box textAlign="right" p={3} position="relative">
      <TextLogo small />
    </Box>
  </FooterContainer>
);

export default Footer;
