import styled from "styled-components";

interface BoxProps {
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  display?: string;
  flexDirection?: string;
  background?: string;
  alignItems?: string;
  justifyContent?: string;
  overflow?: string;
  flexWrap?: string;
  position?: string;
  right?: string;
  top?: string;
  left?: string;
  overflowY?: string;
  bottom?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  flex?: string | boolean;
  borderRadius?: string;
  zIndex?: string;
  pointerEvents?: string;
  border?: string;
}

const Box = styled.div<BoxProps>`
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
  ${({ padding }) => (padding ? `padding: ${padding};` : "")}
	${({ border }) => (border ? `border: ${border};` : "")}

  ${({ width }) => (width ? `width: ${width};` : "")}
  ${({ height }) => (height ? `height: ${height};` : "")}

  ${({ right }) => (right ? `right: ${right};` : "")}
  ${({ left }) => (left ? `left: ${left};` : "")}
  ${({ top }) => (top ? `top: ${top};` : "")}
  ${({ bottom }) => (bottom ? `bottom: ${bottom};` : "")}
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : "")}
  ${({ pointerEvents }) =>
    pointerEvents ? `pointer-events: ${pointerEvents};` : ""}

  ${({ display }) => (display ? `display: ${display};` : "")}
  ${({ flexWrap = "wrap" }) => `flex-wrap: ${flexWrap};`}
  ${({ flexDirection }) =>
    flexDirection ? `flex-direction: ${flexDirection};` : ""}
  ${({ background }) => (background ? `background: ${background};` : "")}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : "")}
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ""}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : "")}
  ${({ overflowY }) => (overflowY ? `overflow-y: ${overflowY};` : "")}
  ${({ position }) => (position ? `position: ${position};` : "")}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : "")}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : "")}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : "")}
  ${({ flex }) =>
    flex === true ? "display: flex;" : !flex ? `flex: ${flex};` : ""}
  ${({ borderRadius }) =>
    borderRadius ? `border-radius: ${borderRadius};` : ""}
`;

export default Box;
