import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Fragment, useGlobal, useState } from "reactn";

import { TextLogo } from ".";
import ButtonLink from "./ButtonLink";
import CustomLink from "./CustomLink";
import UserMenu from "./UserMenu";

const links = [
  {
    name: "Stats",
    link: "/stats",
    authenticated: true,
    bits: [3],
  },
  {
    name: "Editor",
    link: "/editor",
    authenticated: true,
    bits: [3],
  },
  {
    name: "Commands",
    link: "/commands",
    authenticated: false,
    bits: [],
  },
];

export interface HeaderProps {
  showLogo?: boolean;
}

const Header: React.FC<HeaderProps> = ({ showLogo = true }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user] = useGlobal("user");
  const _links = !user
    ? [] //links.filter(l => !l.authenticated)
    : links.filter((l) => l.bits.every((bit) => user.bits.includes(bit)));
  return (
    <Fragment>
      <AppBar elevation={0} color="transparent" position="static">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            position="relative"
            textAlign="center"
          >
            <div />
            {showLogo ? (
              <Box
                margin="20px auto"
                padding="20px 0px"
                position="absolute"
                left="0"
                right="0"
                marginBottom="-10px"
              >
                <TextLogo />
              </Box>
            ) : (
              <div />
            )}
            <Box
              component="nav"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Hidden smDown>
                {_links.map((link) => (
                  <Button
                    key={link.link}
                    component={ButtonLink}
                    to={link.link}
                    color="secondary"
                  >
                    {link.name}
                  </Button>
                ))}
                <UserMenu hideName />
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  onClick={() => setMenuOpen(!menuOpen)}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        disableSwipeToOpen={false}
        disableBackdropTransition
        anchor="right"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
      >
        <Box width={200}>
          <List component="nav">
            <UserMenu
              boxProps={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            />
            {_links.map((link) => (
              <CustomLink key={link.link} to={link.link}>
                <ListItem button key={link.link}>
                  <ListItemText primary={link.name} />
                </ListItem>
              </CustomLink>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};

export default Header;
