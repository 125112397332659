interface Command {
  name: string;
  description: string;
  examples: string[];
}

export const commands: Command[] = [
  {
    name: "daily",
    description: "Claim a free daily reward.",
    examples: ["-daily"],
  },
  {
    name: "cards",
    description: "View the cards you own.",
    examples: ["-cards", "-cards 5"],
  },
  {
    name: "redirect",
    description: "Redirects card spawns to a specified channel.",
    examples: ["-redirect #spawns"],
  },
  {
    name: "prefix",
    description: "Shows you the current prefix, or allows you to change it.",
    examples: ["-prefix +", "-prefix"],
  },
];
