import Typography from "@material-ui/core/Typography";
import { chunk } from "e";
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Tilt from "react-parallax-tilt";
import { useEffect } from "reactn";
import styled from "styled-components";

import { Box, ButtonLink } from "../components";
import { bp, INVITE_URL, SUPPORT_SERVER_INVITE } from "../constants";
import BookImage from "../assets/book.svg";
import CardsImage from "../assets/cards.svg";
import LogoImage from "../assets/logo.webp";
import { apiFetch } from "../util";
const Hero = styled.div`
  width: 100%;
  padding: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-self: center;

  margin: 90px auto;
  padding: 45px 0px;
  border-radius: 15px;
  max-width: 1400px;

  ${bp.only("xs")} {
    .MuiButton-root {
      margin-bottom: 35px;
    }
    svg {
      margin-bottom: 35px;
    }
  }

  ${bp.up("xs")} {
    .logo {
      width: 400px;
    }
    .info {
      max-width: 560px;
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }
  }

  .cards {
    width: 220px;
    height: 220px;
  }
`;

const HomePage: React.FC = () => {
  const [names, setNames] = useState<[string[], string[]]>([[], []]);
  useEffect(() => {
    apiFetch({ path: "/home" }).then((res) =>
      setNames(
        chunk(res.names, Math.ceil(res.names.length / 2)) as [
          string[],
          string[]
        ]
      )
    );
  }, []);

  return (
    <>
      <Hero>
        <Tilt>
          <img className="logo" width="auto" alt="" src={LogoImage} />
        </Tilt>
        <Box className="info">
          <Box padding="15px" borderRadius="5px" margin="0 0 30px 0">
            <Typography
              style={{
                lineHeight: 1.1,
                fontSize: 21,
                textAlign: "right",
              }}
              gutterBottom
            >
              GamerCards is a discord bot where you collect cards of your
              favorite game characters.
            </Typography>
          </Box>

          <Box flex justifyContent="space-around">
            <ButtonLink
              to={INVITE_URL}
              buttonProps={{
                variant: "contained",
                color: "secondary",
                size: "large",
              }}
            >
              Invite to Your Server
            </ButtonLink>
            <ButtonLink
              to={SUPPORT_SERVER_INVITE}
              buttonProps={{
                variant: "outlined",
                color: "secondary",
                size: "large",
              }}
            >
              Join the Support Server
            </ButtonLink>
          </Box>
        </Box>
      </Hero>

      <Marquee gradientColor={[0, 0, 0]}>
        {names[0].map((n, i) => (
          <Typography
            color={i % 2 === 0 ? "textPrimary" : "secondary"}
            variant="h5"
            gutterBottom
            style={{ marginRight: 30 }}
          >
            {n}
          </Typography>
        ))}
      </Marquee>
      <Marquee gradientColor={[0, 0, 0]} direction="right">
        {names[1].map((n, i) => (
          <Typography
            color={i % 2 === 0 ? "textPrimary" : "secondary"}
            variant="h5"
            gutterBottom
            style={{ marginRight: 30 }}
          >
            {n}
          </Typography>
        ))}
      </Marquee>

      <Hero>
        <Tilt>
          <Box flex justifyContent="center">
            <img src={BookImage} className="cards" />
          </Box>
        </Tilt>
        <Box className="info">
          <Box padding="15px" borderRadius="5px" margin="0 0 30px 0">
            <Typography
              style={{
                lineHeight: 1.1,
                fontSize: 21,
                textAlign: "left",
              }}
              gutterBottom
            >
              Catch cards that spawn in your server.
            </Typography>
            <br />
            <Typography
              style={{
                textAlign: "justify",
              }}
            >
              After you add the bot to your server, random cards will start
              spawning in your channels when messages are sent. If you're lucky,
              you'll get a card of a rare character.
            </Typography>
          </Box>
        </Box>
      </Hero>
      <Hero>
        <Tilt>
          <Box flex justifyContent="center">
            <img src={CardsImage} className="cards" />
          </Box>
        </Tilt>
        <Box className="info">
          <Box padding="15px" borderRadius="5px" margin="0 0 30px 0">
            <Typography
              style={{
                lineHeight: 1.1,
                fontSize: 21,
                textAlign: "right",
              }}
              gutterBottom
            >
              Collect your favorite characters from your favorite games.
            </Typography>
            <br />
            <br />
            <br />
            <Typography
              style={{
                textAlign: "justify",
              }}
            >
              GamerCards has thousands of characters, and we're always adding
              more. You can collect characters from your favorite games, and
              complete your collection.
            </Typography>
          </Box>
        </Box>
      </Hero>
      <Hero>
        <ButtonLink
          to={INVITE_URL}
          buttonProps={{
            variant: "contained",
            color: "secondary",
            size: "large",
          }}
        >
          Invite The Bot To Your Server
        </ButtonLink>
      </Hero>
    </>
  );
};

export default HomePage;
