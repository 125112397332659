import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import ReactDOM from "react-dom";
import { setGlobal } from "reactn";

import { GlobalState } from "./constants";
import Root from "./Root";
import { loadState } from "./util";

const localState = loadState();

setGlobal({
  authenticated:
    Boolean(localState.discord_user) && Boolean(localState.discord_token),
  user: localState.discord_user,
  token: localState.discord_token,
} as GlobalState).catch(() => null);

ReactDOM.render(<Root />, document.getElementById("root"));
