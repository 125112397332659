import { Route as RouterRoute, RouteProps } from "react-router-dom";
import React, { useGlobal } from "reactn";

import { history } from "../constants";

interface Props extends RouteProps {
  /**
   * Can *only* be visited if not authenticated.
   */
  authenticated?: boolean;
  /**
   * Can *only* be visited if not authenticated.
   */
  unauthenticated?: boolean;
}

const Route: React.FC<Props> = ({
  component: Component,
  authenticated = false,
  unauthenticated = false,
  ...rest
}) => {
  const [global] = useGlobal();

  if ((global.user && unauthenticated) || (!global.user && authenticated)) {
    history.push("/");
  }

  return <RouterRoute {...rest} component={Component} />;
};

export default Route;
