import Link from "@material-ui/core/Link";
import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const CustomLink: React.FC<LinkProps> = (props) => {
  const to = props.to as string;

  if (to.startsWith("/")) {
    return (
      <Link color="secondary" to={to} component={RouterLink}>
        {props.children}
      </Link>
    );
  }

  return (
    <Link
      color="secondary"
      component="a"
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Link>
  );
};

export default CustomLink;
