import { Box, CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Router, Switch } from "react-router-dom";
import Particles from "react-tsparticles";
import { useEffect, useGlobal } from "reactn";

import { Header, Route } from "./components";
import Footer from "./components/Footer";
import { history, oauthURL, theme } from "./constants";
import { CommandsPage } from "./pages/Commands";
import HomePage from "./pages/Home";
import { saveState, syncUser } from "./util";

const Editor = React.lazy(() => import("./pages/Editor"));
const Stats = React.lazy(() => import("./pages/Stats"));
const OAuthPage = React.lazy(() => import("./pages/OAuthPage"));

function LazyCmpt(Component: any) {
  return (
    <React.Suspense fallback={<p>Loading...</p>}>
      <Component />
    </React.Suspense>
  );
}

const Root = () => {
  const [user, setUser] = useGlobal("user");
  useEffect(() => {
    async function sync() {
      if (user) {
        const data = await syncUser();
        saveState("discord_user", { ...user, ...data });
        setUser({ ...user, ...data });
      }
    }
    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.StrictMode>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Box marginTop="60px" minHeight="85vh">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route
                authenticated
                path="/editor"
                render={() => LazyCmpt(Editor)}
              />
              <Route
                authenticated
                path="/stats"
                render={() => LazyCmpt(Stats)}
              />
              <Route
                unauthenticated
                path="/login"
                render={() => {
                  window.location.replace(oauthURL.toString());
                  return null;
                }}
              />
              <Route
                unauthenticated
                path="/oauth/callback"
                component={OAuthPage}
              />
              <Route path="/commands" component={CommandsPage} />
              <Route component={() => <p>Not found</p>} />
            </Switch>
          </Box>
          <Box position="absolute">
            <Particles
              options={{
                autoPlay: true,
                background: {
                  color: {
                    value: "#000",
                  },
                  image: "",
                  position: "",
                  repeat: "",
                  size: "",
                  opacity: 0,
                },
                fullScreen: {
                  enable: true,
                  zIndex: -1,
                },
                detectRetina: true,
                fpsLimit: 60,
                manualParticles: [],
                motion: {
                  disable: false,
                  reduce: {
                    factor: 4,
                    value: true,
                  },
                },
                particles: {
                  bounce: {
                    horizontal: {
                      random: {
                        enable: false,
                        minimumValue: 0.1,
                      },
                      value: 1,
                    },
                    vertical: {
                      random: {
                        enable: false,
                        minimumValue: 0.1,
                      },
                      value: 1,
                    },
                  },
                  color: {
                    value: "#1de9b6",
                    animation: {
                      h: {
                        count: 0,
                        enable: false,
                        offset: 0,
                        speed: 1,
                        sync: true,
                      },
                      s: {
                        count: 0,
                        enable: false,
                        offset: 0,
                        speed: 1,
                        sync: true,
                      },
                      l: {
                        count: 0,
                        enable: false,
                        offset: 0,
                        speed: 1,
                        sync: true,
                      },
                    },
                  },
                  life: {
                    count: 0,
                    delay: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                      sync: false,
                    },
                    duration: {
                      random: {
                        enable: false,
                        minimumValue: 0.0001,
                      },
                      value: 0,
                      sync: false,
                    },
                  },
                  move: {
                    angle: {
                      offset: 45,
                      value: 90,
                    },
                    attract: {
                      enable: false,
                      rotate: {
                        x: 3000,
                        y: 3000,
                      },
                    },
                    decay: 0,
                    distance: 0,
                    direction: "none",
                    drift: 0,
                    enable: true,
                    gravity: {
                      acceleration: 9.81,
                      enable: false,
                      maxSpeed: 50,
                    },
                    path: {
                      clamp: true,
                      delay: {
                        random: {
                          enable: false,
                          minimumValue: 0,
                        },
                        value: 0,
                      },
                      enable: false,
                    },
                    outModes: {
                      default: "out",
                      bottom: "out",
                      left: "out",
                      right: "out",
                      top: "out",
                    },
                    random: false,
                    size: false,
                    speed: 0.4,
                    straight: false,
                    vibrate: false,
                    warp: true,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                      factor: 1000,
                    },
                    limit: 0,
                    value: 500,
                  },
                  opacity: {
                    random: {
                      enable: true,
                      minimumValue: 0.3,
                    },
                    value: {
                      min: 0.001,
                      max: 0.2,
                    },
                    animation: {
                      count: 0,
                      enable: true,
                      speed: 0.5,
                      sync: false,
                      destroy: "none",
                      minimumValue: 0.3,
                      startValue: "random",
                    },
                  },
                  reduceDuplicates: false,
                  size: {
                    random: {
                      enable: true,
                      minimumValue: 0.001,
                    },
                    value: 1,
                    animation: {
                      count: 0,
                      enable: true,
                      speed: 3,
                      sync: false,
                      destroy: "none",
                      minimumValue: 1,
                      startValue: "random",
                    },
                  },
                  twinkle: {
                    lines: {
                      enable: false,
                      frequency: 0.05,
                      opacity: 1,
                    },
                    particles: {
                      enable: true,
                      frequency: 0.000_01,
                      opacity: 1,
                    },
                  },
                },
                pauseOnBlur: true,
                pauseOnOutsideViewport: true,
                responsive: [],
                themes: [],
              }}
            />
          </Box>
          <Footer />
        </ThemeProvider>
      </Router>
    </React.StrictMode>
  );
};

export default Root;
