import { CharacterRarityEnum } from "@gamercards/universal";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { getGlobal, resetGlobal } from "reactn";

import Box from "./components/Box";
import { BASE_API_URL, history } from "./constants";

export type Guild = {
  id: string;
  redirect_channel: string | null;
};

export type User = {
  id: string;
  username: string | null;
  bits: number[];
  last_daily: string | null;
};

export type Card = {
  id: number;
  quality: string;
  created_at: string;
  character_id: number;
  user_id: string;
  creator_id: string;
};

export type Character = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  rarity: CharacterRarityEnum;
  image_url: string;
  game_id: number;
  user_id: string | null;
};

export type Game = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  release_year: number;
  game_series_id: number;
};

export type GameSeries = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
};

interface LocalStorageData {
  lastEditing?: {
    game_series: number;
    game: number;
    character: number;
  };
  discord_user?: any;
  discord_token?: string;
}

export const loadState = (): LocalStorageData => {
  const keys = Object.keys(localStorage);
  let res: any = {};
  for (const key of keys) {
    try {
      res[key] = JSON.parse(localStorage.getItem(key)!)!;
    } catch {}
  }
  return res as LocalStorageData;
};

export const saveState = (key: keyof LocalStorageData, val: any) => {
  try {
    const serializedState = JSON.stringify(val);
    localStorage.setItem(key, serializedState);
  } catch (err) {}
};

export async function apiFetch({
  path,
  method = "GET",
  body,
  headers = {},
  query,
}: {
  path: string;
  method?: string;
  body?: any;
  headers?: Record<string, number>;
  query?: Record<string, string>;
}) {
  if (body) body = JSON.stringify(body);
  const url = new URL(`${BASE_API_URL}${path}`);
  if (query) {
    url.search = new URLSearchParams(query).toString();
  }

  const response = await fetch(url.toString(), {
    method,
    body,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  });

  return response.json();
}

export async function authedFetch(
  path: string,
  method = "GET",
  body?: any,
  headers: any = {}
) {
  headers.authentication = getGlobal().token;
  if (body) body = JSON.stringify(body);

  const response = await fetch(`${BASE_API_URL}${path}`, {
    method,
    body,
    headers: { "Content-Type": "application/json", ...headers },
  });

  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
}

function parseEntityWithDateFields(entry: any) {
  return entry;
}

export async function fetchGameSeries(query: string): Promise<any[]> {
  const res = await authedFetch(`/game_series/search/${query}`);
  return res.gameSeries.map(parseEntityWithDateFields);
}

export function tableFromArr<
  T extends {
    name: string;
    created_at: string;
    updated_at: string;
  }
>(
  arr: readonly T[] = [],
  sort: SortMethod,
  onClick: (item: T) => void,
  maxHeight = 300
) {
  if (arr.length === 0) return null;
  return (
    <Box overflowY="scroll" maxHeight={maxHeight === 0 ? "" : `${maxHeight}px`}>
      <List dense component="nav" aria-label="main mailbox folders">
        {[...arr].sort(sortFns[sort]).map((item, rowIndex) => (
          <ListItem button key={rowIndex} onClick={() => onClick?.(item)}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export function logOut() {
  localStorage.clear();
  resetGlobal();
  history.replace("/");
}

export async function syncUser() {
  const response = await authedFetch("/user/sync");
  return response;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function useInterval(
  callback: () => void | undefined,
  delay: number | null
) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (!savedCallback || !savedCallback.current) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      savedCallback?.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;

export const sortMethods = [
  "alpha_asc",
  "alpha_desc",
  "created_asc",
  "created_desc",
  "updated_asc",
  "updated_desc",
] as const;
export type SortMethod = typeof sortMethods[number];
type Sortable = { name: string; created_at: string; updated_at: string };
const sortFns: Record<SortMethod, (a: Sortable, b: Sortable) => number> = {
  alpha_asc: (a, b) => a.name.localeCompare(b.name),
  alpha_desc: (a, b) => b.name.localeCompare(a.name),
  created_asc: (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  created_desc: (a, b) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  updated_asc: (a, b) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
  updated_desc: (a, b) =>
    new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
};

export const SortMenu: React.FC<{
  currentVal: SortMethod;
  fn: (val: SortMethod) => unknown;
}> = ({ currentVal, fn }) => {
  return (
    <div>
      <select value={currentVal} onChange={(e) => fn(e.target.value as any)}>
        {sortMethods.map((method) => (
          <option onClick={() => fn(method)} key={method} value={method}>
            {method}
          </option>
        ))}
      </select>
    </div>
  );
};
