import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import React, { Fragment, useGlobal } from "reactn";

import { logOut } from "../util";
import ColoredButton from "./ColoredButton";

interface Props {
  boxProps?: BoxProps;
  hideName?: boolean;
}

const UserMenu: React.FC<Props> = ({ boxProps, hideName }) => {
  const [user] = useGlobal("user");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user) {
    return (
      <Fragment>
        <Box {...boxProps}>
          {!hideName && (
            <Typography variant="body1">{user.username}</Typography>
          )}
          <Button color="secondary" onClick={handleClick}>
            <Avatar src={user.avatar} alt="" />
            <ExpandMoreIcon />
          </Button>
        </Box>

        <Menu
          style={{ marginTop: 25 }}
          onClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={handleClose}
        >
          <MenuItem component="a" onClick={logOut}>
            Logout
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  return null;
};

export default UserMenu;
