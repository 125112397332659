import { Container, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { commands } from "../commands";
import { Box } from "../components";
import { theme } from "../constants";

const Command = styled.div`
  padding: 25px;
  background: ${theme.palette.primary.dark};
  margin: 15px;
  border-radius: 10px;
`;

export const CommandsPage: React.FC = () => {
  return (
    <>
      <Container maxWidth="md">
        <Box padding="0px 25px">
          <Typography variant="h4">Commands</Typography>
        </Box>
        {commands.map((c) => (
          <Command>
            <Box flex justifyContent="space-between">
              <Box>
                <Typography color="secondary" variant="h5" gutterBottom>
                  -{c.name}
                </Typography>
                <Typography variant="body1">{c.description}</Typography>
                <br />
                <Typography color="secondary" variant="body1" gutterBottom>
                  Examples
                </Typography>
                <Typography variant="body1">
                  {c.examples.map((i) => (
                    <Typography>{i}</Typography>
                  ))}
                </Typography>
              </Box>
            </Box>
          </Command>
        ))}
      </Container>
    </>
  );
};
