import { Bits, CharacterOutput } from "@gamercards/universal";
import { createTheme } from "@material-ui/core";
import { createBrowserHistory } from "history";
import { Character } from "./util";

export let DOCUMENT_HAS_BEEN_CLICKED = false;

document.onclick = () => (DOCUMENT_HAS_BEEN_CLICKED = true);

export const history = createBrowserHistory();

export const PRODUCTION = import.meta.env.NODE_ENV !== "development";
export const DEVELOPMENT = import.meta.env.NODE_ENV === "development";

export const BASE_WEB_URL = import.meta.env.VITE_BASE_WEB_URL!;
export const BASE_API_URL = import.meta.env.VITE_BASE_API_URL!;
export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID!;

const inviteURLs = {
  production: "https://www.gamercards.gg/invite",
  staging: "https://www.gamercards.gg/invite/staging",
  dev: "https://www.gamercards.gg/invite/dev",
} as const;

export const SUPPORT_SERVER_INVITE = "https://discord.gg/6YgNFh5YHY";
export const ENVIRONMENT_TYPE = (import.meta.env.VITE_ENVIRONMENT_TYPE ??
  "production") as "production" | "dev";

export const INVITE_URL = inviteURLs[ENVIRONMENT_TYPE];

export const redirectURL = `${BASE_WEB_URL}/oauth/callback`;

export const oauthURL = new URL("https://discordapp.com/oauth2/authorize");
oauthURL.search = new URLSearchParams([
  ["redirect_uri", redirectURL],
  ["response_type", "code"],
  ["scope", ["identify", "guilds"].join(" ")],
  ["client_id", CLIENT_ID as string],
]).toString();

export const theme = createTheme({
  typography: {
    fontFamily:
      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    type: "dark",
    primary: {
      main: "#1d1f21",
      light: "#36393f",
      dark: "#101010",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1de9b6",
    },
    background: {
      default: "#090909",
    },
  },
});
export const bp = theme.breakpoints;

export interface DiscordUser {
  username: string;
  locale: string;
  premium_type: number;
  mfa_enabled: boolean;
  flags: number;
  avatar: string;
  discriminator: string;
  id: string;
  bits: Bits[];
  cards: any[];
}

export type DiscordToken = string;

export interface GlobalState {
  authenticated: boolean;
  user?: DiscordUser;
  token?: DiscordToken;
}

export type CharacterEditFn = (
  existingCharacter: Character,
  character: CharacterOutput,
  image: string
) => Promise<void>;

export type CharacterAddFn = (
  character: CharacterOutput,
  imageURL: string
) => Promise<void>;
