import { Button } from "@material-ui/core";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  buttonProps?: any;
}

const ButtonLink: React.FC<Props> = (props) => {
  const to = props.to as string;

  if (to.startsWith("/")) {
    return (
      <Button {...props.buttonProps} color="secondary" component={Link} to={to}>
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      {...props.buttonProps}
      color="secondary"
      component="a"
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Button>
  );
};

export default ButtonLink;
